import React ,{Component} from 'react'
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import LabelInput from './labelInput'

class ContactForm extends Component {

	state = {
		name: "",
		_replyto: "",
		phone: "",
		company: "",
		message: "",

		nameError: [],
		_replytoError: "",
		phoneError: "",
		messageError: []
	}
	formValidate = () => {
		const { name, _replyto, phone, message } = this.state;
		let nameError = [];
		let _replytoError = "";
		let phoneError = "";
		let messageError = [];
		let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (name === "") {
			nameError.push("This field cannot be empty.");
		}

		if (name.length <= 3) {
			nameError.push("The name is too short.");
		}

		if (reg.test(_replyto) === false) {
			_replytoError = "Invalid email.";
		}

		if (phone.match(/[a-z]/i)) {
			phoneError = "This field cannot contains letters.";
		}

		if (message === "") {
			messageError.push("This field cannot be empty.");
		}

		if (message.length <= 20) {
			messageError.push("The message is too short.");
		}

		let newState = {...this.state};
		newState = {
			...newState,
			nameError,
			_replytoError,
			phoneError,
			messageError
		};

		this.setState(newState);

		if(nameError.length === 0 && _replytoError === "" && phoneError === "" && messageError.length === 0){
			return true;
		} else {
			return false;
		}
	}
	handleInputChange = event => {
		const target = event.target
		const value = target.value
		const name = target.name

		this.setState({
			[name]: value,
		})
	}

	handleSubmit = event => {
		event.preventDefault();
		const isValid = this.formValidate();

		if (isValid){
			event.target.submit();
		} else {
			return false;
		}
	}

	render() {
		let { data } = this.props;
		return (
			<div className="container">
				<div className="contact-form" style={{backgroundImage: `url(${data.dnhCms.images[0].asset.url})`}}>
					<div className="contact-form__top">
						<div className="headers-wrap">
							<h4>Do not hesitate to</h4>
							<h3>Contact Us</h3>
						</div>
						<p>
							
						Tell us more about your challenge<br /> and inquire how we can help your business!
						</p>
					</div>
					<form
						onSubmit={this.handleSubmit}
						action="https://formspree.io/xwkolgby"
						method="POST">
						<div className="label-wrap">
							<LabelInput labelClass={`contact-form__label ${this.state.nameError.length === 0 ? '': 'error'}`}
										labelName="Name"
										inputClass="contact-form__input"
										inputType="text"
										inputName="name"
										inputValue={this.state.name}
										inputOnChange={this.handleInputChange} />
							{this.state.nameError ?
								<div className="error-wrap">
									{this.state.nameError.map((error, index) => (
										<span key={index}>{error}</span>
									))}
								</div>
							: null}
						</div>
						<div className="label-wrap">
							<LabelInput labelClass={`contact-form__label ${this.state._replytoError === "" ? '' : 'error'}`}
										labelName="Email"
										inputClass="contact-form__input"
										inputType="email"
										inputName="_replyto"
										inputValue={this.state.email}
										inputOnChange={this.handleInputChange} />
							{this.state._replytoError ?
								<div className="error-wrap">
									<span>{this.state._replytoError}</span>
								</div>
							: null}
						</div>
						<div className="label-wrap">
							<LabelInput labelClass={`contact-form__label ${this.state.phoneError === "" ? '' : 'error'}`}
										labelName="Phone"
										inputClass="contact-form__input"
										inputType="tel"
										inputName="phone"
										inputValue={this.state.phone}
										inputOnChange={this.handleInputChange} />
							{this.state.phoneError ?
								<div className="error-wrap">
									<span>{this.state.phoneError}</span>
								</div>
							: null}
						</div>
						<LabelInput labelClass="contact-form__label"
									labelName="Company"
									inputClass="contact-form__input"
									inputType="text"
									inputName="company"
									inputValue={this.state.company}
									inputOnChange={this.handleInputChange} />
						<div className="label-wrap full-width">
							<label className={`contact-form__label ${this.state.messageError.length === 0 ? '': 'error'}`}>
								MESSAGE
								<textarea
									className="contact-form__textarea"
									name="message"
									value={this.state.message}
									onChange={this.handleInputChange}
								/>
							</label>
							{this.state.messageError ?
								<div className="error-wrap">
									{this.state.messageError.map((error, index) => (
										<span key={index}>{error}</span>
									))}
								</div>
							: null}
						</div>
						<button className="contact-form__submit" type="submit">SEND YOUR MESSAGE</button>
					</form>
				</div>
			</div>
		)
	}

}

ContactForm.propTypes = {
	data: PropTypes.object
}

const ContactFormContainer = (props) => {
	const data = useStaticQuery(graphql`
		query {
			dnhCms{
				images(where: {title: "Map"}) {
					asset {
						url
					}
				}
			}
		}
	`)

	return (
		<ContactForm data={data} {...props}/>
	)
}

export default ContactFormContainer
