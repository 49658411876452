import React from "react"
import PropTypes from "prop-types"

const LabelInput = ({labelClass, labelName, inputClass, inputType, inputName, inputValue, inputOnChange}) => (
	<label className={labelClass}>
		{labelName}
		<input
			className={inputClass}
			type={inputType}
			name={inputName}
			value={inputValue}
			onChange={inputOnChange}
		/>
	</label>
)

LabelInput.propTypes = {
	labelClass: PropTypes.string,
	labelName: PropTypes.string,
	inputClass: PropTypes.string,
	inputType: PropTypes.string,
	inputName: PropTypes.string,
	inputValue: PropTypes.string,
	inputOnChange: PropTypes.func
}

export default LabelInput
