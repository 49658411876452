import React from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = 'pk.eyJ1IjoibHlraSIsImEiOiJjazUyaHMzYnAwejF3M2txbmtqaWY4aWJ0In0.p0soOYVoavtJVXoHWjNDSw';

class Map extends React.Component {
	constructor(props) {
		super(props);
		this.marker
		this.state = {
			lng: 24.7466,
			lat: 42.1506,
			zoom: 15
		};
	}

	componentDidMount() {
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: 'mapbox://styles/mapbox/streets-v11?optimize=true',
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom
		});

		this.marker = new mapboxgl.Marker({
			color: '#FF7F2A',
			markerSize: 'large'
		})
		.setLngLat([this.state.lng, this.state.lat])
		.addTo(map);

		map.scrollZoom.disable();
		map.addControl(new mapboxgl.NavigationControl());
	}

	render() {
		return (
		<div className="map-wrap container">
			<div ref={el => this.mapContainer = el} className='map' />
		</div>
		)
	}
}

export default Map
