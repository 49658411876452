import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from 'react-helmet'

import Layout from "../components/layout"
import SEO from "../components/seo"
import TextSection from "../components/textSection"
import InfoCard from "../components/infoCard"
import Map from "../components/map"
import ContactForm from "../components/contactForm"

const ContactUs = (props) => {
	const data = useStaticQuery(graphql`
		query Contact {
			dnhCms {
				pages(where: {pageTitle: "Contact us", status: PUBLISHED}) {
					id
					bannerHeading
					pageTitle
					heading
					copy
					pageBanner {
						url
					}
				}
			}
		}
	`)
	return(
		<>
			<Helmet
				bodyAttributes={{
					class: data.dnhCms.pages[0].pageTitle.replace(/\s+/g, '-').toLowerCase()
				}}
			/>
			<Layout location={props.location.pathname}
					bannerHeading={data.dnhCms.pages[0].bannerHeading}
					bannerUrl={data.dnhCms.pages[0].pageBanner.url}>
				<SEO title={data.dnhCms.pages[0].pageTitle} />
				<TextSection heading={data.dnhCms.pages[0].heading}
							copy={data.dnhCms.pages[0].copy}
							sectionClass="contact"
							textSize=""
							textColor="blue"
							bgColor="grayBg"/>
				<div className="info-card-wrap">
					<InfoCard />
				</div>
				<Map />

				<ContactForm />

			</Layout>
		</>
	)
}

ContactUs.propTypes = {
	location: PropTypes.object
}

export default ContactUs
